import "./App.css";
import React, { useState } from "react";
import axios from "axios";
import { Link, Element } from "react-scroll";

function App() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oratiotechnologies.com/send",
        formData
      );
      alert("Email sent successfully: " + response.data);
    } catch (error) {
      console.error("There was an error sending the email:", error);
      alert("Failed to send email");
    }
  };

  return (
    <div className="App">
      <header>
        <div className="navbar">
          <div className="logo">Oratio</div>
          <nav>
            <a href="#product">Product</a>
            <a href="#about">About us</a>
            <a href="#solution">Solution</a>
            <a href="#plans">Plans</a>
          </nav>

          <Link
            to="book-demo"
            smooth={true}
            duration={500}
            className="navbar-button book-demo-btn"
          >
            Book a Demo
          </Link>
        </div>
      </header>

      <section className="hero">
        <div className="hero-text">
          <h1>Transforming Communication with Intelligent Chatbots</h1>
          <p>
            Our chatbot technology empowers businesses and end-users by
            streamlining communication and providing instant access to
            information.
          </p>
          <div className="hero-buttons">
            <Link
              to="book-demo"
              smooth={true}
              duration={500}
              className="book-demo-btn"
            >
              Book a Demo
            </Link>
            <button className="secondary">Take a tour</button>
          </div>
        </div>
        <div className="hero-image">
          <img src={`${process.env.PUBLIC_URL}/under.jpg`} alt="Hero" />
        </div>
      </section>

      <section className="features">
        <h2>Experience the Power of AI Chatbots</h2>
        <p className="features-description">
          Our chatbots are equipped with advanced AI capabilities to provide
          accurate and efficient responses. They can communicate with various
          file formats, such as docs and pdfs, and cite the sources of their
          answers.
        </p>
        <div className="feature-list">
          <div className="feature-item">
            <div className="hero-image1">
              <img src={`${process.env.PUBLIC_URL}/files.png`} alt="Hero" />
            </div>
            <h3>File Compatibility</h3>
            <p>
              Our chatbots can handle various file formats, including docs and
              pdfs.
            </p>
          </div>
          <div className="feature-item">
            <div className="hero-image1">
              <img src={`${process.env.PUBLIC_URL}/image 1.png`} alt="Hero" />
            </div>
            <h3>AI Response Generation</h3>
            <p>
              Powered by AI, our chatbots generate accurate and intelligent
              responses.
            </p>
          </div>
          <div className="feature-item">
            <div className="hero-image1">
              <img src={`${process.env.PUBLIC_URL}/sources.png`} alt="Hero" />
            </div>
            <h3>Document Citation in Responses</h3>
            <p>
              Our chatbots provide the source of information used in their
              responses.
            </p>
          </div>
        </div>
        <div className="feature-buttons">
          <Link
            to="book-demo"
            smooth={true}
            duration={500}
            className="book-demo-btn"
          >
            Book a Demo
          </Link>

          <button className="secondary">Take a tour</button>
        </div>
      </section>

      <section className="communication">
        <div className="communication-text">
          <h2>Enhance Communication with AI-Powered Chatbots</h2>
          <ul>
            <li>Efficiently retrieve information from documents</li>
            <li>Get accurate answers from AI-powered chatbots</li>
            <li>Streamline your document-based communication process</li>
          </ul>
        </div>
        <div className="communication-image">
          <img src={`${process.env.PUBLIC_URL}/com.png`} alt="Communication" />
        </div>
      </section>

      <section className="empowerment">
        <div className="empowerment-image">
          <img src={`${process.env.PUBLIC_URL}/emp.png`} alt="Empowerment" />
        </div>
        <div className="empowerment-text">
          <h2>Empowering Communication Through Intelligent Chatbots</h2>
          <p>
            We are a team of experts dedicated to developing chatbots that can
            effectively communicate with various file types, providing
            intelligent responses and referencing the relevant documents. Our
            mission is to revolutionize the way people interact with
            information.
          </p>
        </div>
      </section>

      <section className="future">
        <div className="future-text">
          <h2>Experience the Future of Chatbots</h2>
          <p>
            Discover how our AI-powered chatbots can revolutionize your
            business.
          </p>
        </div>
        <div className="future-buttons">
          <Link
            to="book-demo"
            smooth={true}
            duration={500}
            className="book-demo-btn"
          >
            Book a Demo
          </Link>

          <button className="secondary">Take a tour</button>
        </div>
      </section>

      <Element name="book-demo">
        <section className="contact">
          <div className="contact-text">
            <h2>Get in Touch</h2>
            <p>
              Fill in your details to book a demo call with one of our product
              experts
            </p>
            <ul>
              <li>A no-commitment product walkthrough</li>
              <li>Discussion built on your top priorities</li>
              <li>Your questions, answered</li>
            </ul>
          </div>
          <div className="contact-form">
            <h2>Book your Demo</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-inline">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name*"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name*"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <input
                type="text"
                name="companyName"
                placeholder="Company Name*"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="country"
                placeholder="Country*"
                value={formData.country}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Business email*"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <p>
                <i className="info-icon"></i> We will only contact you to book
                an appointment that works for you and understand your specific
                requirements.
              </p>
              <p>
                *For the best experience possible, we ask that you provide all
                of the required contact details above
              </p>

              <button type="submit">Book a Demo</button>
            </form>
          </div>
        </section>
      </Element>

      <footer>
        <div className="footer-links">
          <div className="column">
            <h3>About Us</h3>
            <a href="#product">Product</a>
            <a href="#demo">Demo</a>
            <p>
              Contact Us :{" "}
              <a href="mailto:contact@oratiotechnologies.com">
                contact@oratiotechnologies.com
              </a>
            </p>
          </div>
          <div className="column">
            <h3>Solution</h3>
            <a href="#link4">Our Solution</a>
          </div>
          <div className="column">
            <h3>Plans</h3>
            <a href="#link4">Our plans</a>
          </div>
          <div className="column">
            <h3>Subscribe</h3>
            <p>Stay updated with Our Newsletter</p>
            <input
              className="subinput"
              type="email"
              placeholder="Enter Email Address"
            />
            <button className="subscribe">Subscribe</button>
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 Oratio Technologies. All rights reserved.</p>
          <p>Address: 19 Residence Erriadh, La Marsa, 2070 Tunis</p>
          <div className="footer-bottom-links">
            <a href="#privacy">Privacy Policy</a>
            <a href="#terms">Terms of Service</a>
            <a href="#cookies">Cookie Settings</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
